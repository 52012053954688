.container {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background-color: var(--gray);
}

.rewardBox {
    display: flex;
    align-items: center;
}

.rewardIcon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

.container p {
    padding: 0;
    margin: 0;
}